<template>
  <div>
    <!-- table -->
    <MyTable
      :rows="orders"
      :columns="columns"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'delivery.id'">
          <b-button
            variant="primary"
            :to="{ name: 'DeliveryTracking', params: { orderId: props.row.id } }"
          >
            {{ props.row.delivery.id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'id'">
          <b-button
            variant="primary"
            :to="{ name: 'OrderSummary', params: { orderId: props.row.id } }"
          >
            {{ props.row.reference_id }}
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'delivery_date'">
          {{ $helpers.formatDateTime(props.row.delivery_date) }}
        </span>

        <span v-else-if="props.column.field === 'smartCut'">
          {{ $helpers.formatTotal(+props.row.smarttruck_cut + +props.row.pickup_charge) }}
        </span>

        <span v-else-if="props.column.field === 'pickup_photo'">
          <b-button
            v-if="props.row.has_pickup_photo"
            variant="success"
            class="font-weight-bolder w-100"
            @click="showEvidence(props.row.pickup_photo, props.row.id, 'pickup')"
          >
            <FeatherIcon icon="EyeIcon" /> Pickup Photo
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'pickup')"
          >
            Upload
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'delivered_photo'">
          <b-button
            v-if="props.row.has_delivered_photo"
            variant="success"
            class="font-weight-bolder w-100"
            @click="showEvidence(props.row.delivered_photo, props.row.id, 'delivery')"
          >
            <FeatherIcon icon="EyeIcon" /> Delivery Photo
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'delivered')"
          >
            Upload
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'grn_photo'">
          <b-button
            v-if="props.row.has_good_return_photo"
            variant="success"
            class="font-weight-bolder w-100"
            @click="showEvidence(props.row.good_return_photo, props.row.id, 'grn')"
          >
            <FeatherIcon icon="EyeIcon" /> Good Return Note
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="font-weight-bolder w-100"
            @click="upload(props.row.id, 'grn')"
          >
            Upload
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'status'">
          <b-badge
            :variant="`${props.row.status.color_name}`"
            class="font-weight-bolder d-block w-100 p-1"
          >
            {{ props.row.status.text }}
          </b-badge>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <b-modal
      ref="image"
      size="lg"
      ok-only
      ok-title="Close"
    >
      <b-card v-if="imageURL">
        <b-img
          :src="imageURL"
          class="w-100"
        />
      </b-card>
      <b-spinner v-else />
    </b-modal>
  </div>
</template>

<script>
import store from '@/store/index'
import { required } from '@validations'
import { mapGetters } from 'vuex'
import MyTable from '@/components/MyTable.vue'

export default {
  name: 'DeliveryHistory',
  components: {
    MyTable,
  },
  data() {
    return {
      orderId: null,
      imageURL: null,
      option: null,
      showSelectReason: false,
      uploading: false,
      required,
      pageLength: 5,
      form: {},
      dir: false,
      selectedReason: { title: 'Accident' },
      selected: null,
      toSelect: false,
      selectedGroup: null,
      groupToUpdate: null,
      columns: [
        {
          label: this.$t('delivery_id'),
          field: 'delivery.id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left',
          thClass: 'text-left',
        },
        {
          label: this.$t('order_id'),
          field: 'id',
          type: 'number',
          tdClass: 'text-nowrap align-middle text-left',
        },
        {
          label: this.$t('senders_name'),
          field: 'sender.name',
          type: 'text',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('recipients_name'),
          field: 'recipient.name',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('delivery_date_time'),
          field: 'delivery_date',
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('total_earn'),
          field: 'smartCut',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('pickup_image'),
          field: 'pickup_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('delivered_image'),
          field: 'delivered_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('good_return_note'),
          field: 'grn_photo',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
        {
          label: this.$t('status'),
          field: 'status',
          sortable: false,
          tdClass: 'text-nowrap align-middle',
        },
      ],
      rows: [],
      searchTerm: '',
      options: [
        { value: 2, title: 'Assigned' },
        { value: 3, title: 'Arrive at Sender' },
        { value: 4, title: 'Arrive at Golog' },
        { value: 5, title: 'In Transit' },
        { value: 6, title: 'Picked by Lorry' },
        { value: 7, title: 'Out for Delivery' },
        { value: 8, title: 'Delivered' },
        { value: 9, title: 'Request for Cancellation' },
      ],
      reasons: [
        { title: 'Accident' },
        { title: 'Vehicle Breakdown' },
        { title: 'Nature Disaster' },
        { title: 'Health Quarantine' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/checkLoadingState',
      orders: 'orders/checkOrders',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getOrders()
  },
  methods: {
    checkstatus() {
      this.showSelectReason = (this.selected.value === 9)
    },
    async showEvidence(url, id, option) {
      this.option = option
      this.orderId = id
      this.imageURL = url
      this.$refs.image.show()
      // this.$swal({
      //   title: `${option.toUpperCase()} Photo`,
      //   imageUrl: url,
      //   imageAlt: `${option} image`,
      //   customClass: {
      //     confirmButton: 'btn btn-primary',
      //   },
      // })
    },
    getOrders() {
      const query = 'orders_for=smarttruck&filter_by=delivery_history'
      this.$store.dispatch('orders/fetchOrders', { query })
    },
    async upload(id, option) {
      const { value: file } = await this.$swal({
        title: `${this.$t('upload')} ${option} ${this.$t('image')}`,
        input: 'file',
        inputAttributes: {
          accept: 'image/*',
          'aria-label': `${this.$t('upload_your')} ${option} ${this.$t('picture')}`,
        },
        confirmButtonText: this.$t('upload'),
        showCloseButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          input: 'my-2 p-1',
        },
        inputValidator: value => new Promise(resolve => {
          if (!value) {
            resolve(this.$t('choose_an_image'))
          }
          resolve()
        }),
      })

      if (file) {
        const form = new FormData()
        form.append(option, file)
        form.append('orderId', id)
        const response = await this.$http.post('/uploadDeliveryEvidence', form)
        if (response.data.status) {
          const reader = new FileReader()
          reader.onload = e => {
            this.$swal({
              title: this.$t('your_uploaded_picture'),
              imageUrl: e.target.result,
              imageAlt: this.$t('the_uploaded_picture'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
              .then(() => {
                this.getOrders()
              })
          }
          reader.readAsDataURL(file)
        }
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
